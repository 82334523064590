import { faBell, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'

function OrgTitle({ submissionDate, claimReference, orgDefined, backButton = false }) {
  const navigate = useNavigate()
  const [reference, setReference] = useState(claimReference)
  const goBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    setReference(claimReference)
  }, [claimReference])

  return (
    <div className="pb-4 text-gray-700">
      <div className='flex justify-between items-center pb-4'>
        {backButton && (
          <span
            className="inline-flex items-center text-slate-500 mt-1 cursor-pointer hover:text-slate-600"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <span className="text-sm ml-2 font-bold">Go Back</span>
          </span>
        )}
        <div>
          {/* <a href='https://schooladvice.co.uk/' target='blank' className='bg-nav hover:bg-nav-dark rounded-lg text-white font-medium shadow-md px-4 py-2 cursor-pointer'> <FontAwesomeIcon icon={faBell} className="" /></a> */}
        </div>
      </div>

      <div className='bg-white rounded-lg p-4 flex justify-between text-gray-700'>
        <div >
          <div className='flex items-start justify-between'>
            <div>
              <h2 className='font-semibold text-3xl flex items-center'> 
                PPQ Request - {reference} 
              </h2>
              {submissionDate && (
                <div className='flex items-center space-x-4 text-sm text-gray-500'>
                  <div>Submission Date: {submissionDate}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          {!orgDefined && (
            <>
              <div className='flex items-center space-x-4 text-sm text-red-800'>
              <div>Organisation Not Imported</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrgTitle
