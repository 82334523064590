/* eslint-disable */
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { useState, useEffect, Link, Fragment } from 'react'
import Pagination from '../../../components/Pagination';
import { useParams, useLocation, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faEye, faSortUp, faSort, faSortDown, faPlusCircle, faChevronDown, faUserPlus, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Modal from '../../../components/Modal'
import { Menu, Transition } from '@headlessui/react'
import ViewClaimSlideOut from '../../../components/ViewClaimSlideOut';
import ViewRequest from './ViewRequest';


const OrgPPQ = ({ policy }) => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const { setShowLoader, can, createInfo, auth, admin } = useAuth()
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const navigate = useNavigate();

  const [search, setSearch] = useState('')
  const [statusFilter, setStatusFilter] = useState('');

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [columnClickCounts, setColumnClickCounts] = useState({});
  const [claimOpen, setClaimOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)
  //Modal consts
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  //
  const [enquiries, setEnquiries] = useState([])
  const [clientData, setClientData] = useState([])
  // const [claims, setClaims] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });
  const [claimStatuses, setClaimStatuses] = useState({})
  const [orgStatuses, setOrgStatuses] = useState({})
  const [dropdownValue, setDropdownValue] = useState('');
  const [assignStatus, setAssignStatus] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState("");
  const [referralId, setReferralId] = useState(null);

  useEffect(() => {
    getOrgReferrals()

  }, [id])

  useEffect(() => {
    let updatedRecords = originalRecords;

    // Apply filter if `statusFilter` has a value
    if (statusFilter) {
      updatedRecords = originalRecords.filter((record) =>
        record.status && record.status.toLowerCase() === statusFilter
      );
    }

    setFilteredRecords(updatedRecords);
    setCurrentRecords(updatedRecords.slice(0, recordsPerPage));
    setNumberOfPages(Math.ceil(updatedRecords.length / recordsPerPage));
    setCurrentPage(1);

  }, [statusFilter, originalRecords]);

  // const getClientById = async () => {
  //   try {
  //     setShowLoader(true)
  //     const response = await axios.get(`/clients/` + id)
  //        setClientData(response.data.result)
  //   } catch (error) {
  //     console.error(error)
  //     setShowLoader(false)
  //   }
  //   setShowLoader(false)
  // }

  // const getClientClaims = async () => {
  //   try {
  //     setShowLoader(true)
  //     const response = await axios.get(`/claims/client/` + id)
  //     // let filteredClaims = response.data.result;
  //     // if (statusFilter) {
  //     //   filteredClaims = filteredClaims.filter(claim => claim.status.title.toLowerCase() === statusFilter);
  //     // }
  //     // if (filteredClaims) {
  //       // await setClaims(filteredClaims)
  //       await setOriginalRecords(response.data.result)
  //       await setCurrentRecords(response.data.result)
  //       // await setFilteredRecords(filteredClaims)
  //       setShowLoader(false)
  //     // }
  //   } catch (error) {
  //     console.error(error)
  //     setShowLoader(false)
  //   }
  //   setShowLoader(false)
  // }

  const getOrgReferrals = async () => {
    try {
      setShowLoader(true)
      let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/organisation/${policy.id}/ppq?api_token=${process.env.REACT_APP_API_KEY}`;

      const response = await axios.get(url)
      if (response.data) {
        const requestStatuses = response.data.requests.map(request => request.request_status);
        const uniqueStatuses = [...new Set(requestStatuses)];
        const requestStatus = uniqueStatuses.length === 1 ? uniqueStatuses[0] : uniqueStatuses;
        await setOriginalRecords(response.data.requests)
        await setCurrentRecords(response.data.requests)
        await getOrgStatuses(requestStatus)

        setShowLoader(false)
      }
    } catch (err) {
    }
  }

  const getOrgStatuses = async (requestStatus) => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/organisations/statuses`)
      if (response.data) {
        let statuses = response.data.result;

        if (Array.isArray(requestStatus)) {
          requestStatus.forEach(status => {
            if (!statuses.some(s => s.service_name === status)) {
              statuses.push({ service_name: status });
            }
          });
        } else if (requestStatus) {
          if (!statuses.some(s => s.service_name === requestStatus)) {
            statuses.push({ service_name: requestStatus });
          }
        }

        // Set the combined statuses
        await setOrgStatuses(statuses);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false)
    }
  }

  const handleViewRequest = (request) => {
    setSelectedRequest(request)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSelectedRequest(null)
  }

  const statusClass = (status) => {
    if (typeof status !== 'string') {
      return 'bg-gray-200 text-gray-800'
    }

    switch (status.toLowerCase()) {
      case 'form completed - awaiting result':
      case 'no barrier to work':
      case 'no action required':
        return 'bg-green-200 text-green-800 border-green-400'
      case 'awaiting form completion':
      case 'referral needed':
      case 'in progress':
        return 'bg-amber-200 text-amber-800 border-amber-400'
      case 'closed':
        return 'bg-rose-200 text-rose-800 border-rose-400'
      default:
        if (status.toLowerCase().startsWith('form completed -')) {
          return 'bg-green-200 text-green-800 border-green-400'
        } else {
          return 'bg-gray-200 text-gray-800'
        }
    }
  }

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value.toLowerCase());
    setDropdownValue(e.target.value);
  }

  const resetFilters = () => {
    setStatusFilter('');
    setSearch('');
    setDropdownValue('');
  };

  const isFilterApplied = () => {
    return statusFilter !== '' || search !== '';
  };

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = enquiries.filter((e) => {
        return (
          e.name.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(enquiries)
      setCurrentRecords(enquiries.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(enquiries.length / recordsPerPage))
    }
  }

  const handleSort = (key) => {
    let newColumnClickCounts = { ...columnClickCounts };
    if (!newColumnClickCounts[key]) {
      newColumnClickCounts[key] = 1;
    } else {
      newColumnClickCounts[key]++;
    }

    if (newColumnClickCounts[key] === 3) {
      setSortConfig({
        key: null,
        direction: 'descending',
      });
      newColumnClickCounts[key] = 0;
      setCurrentRecords(originalRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    } else {
      let direction =
        sortConfig.direction === 'ascending'
          ? 'descending'
          : 'ascending';
      setSortConfig({ key, direction });

      const sortedRecords = sortArray(
        originalRecords,
        key,
        direction
      );
      setCurrentRecords(sortedRecords.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      ));
    }
    setColumnClickCounts(newColumnClickCounts);
  };

  const resetModal = () => {
    setShowModal(false)
    setModalTitle('')
  }

  const createClaimClicked = () => {
    setClaimOpen(true)
  }


  const sortArray = (array, key, direction) => {
    return [...array].sort((a, b) => {

      const segments = key.split('.');
      let aKey = a;
      let bKey = b;

      segments.forEach(element => {
        if (!aKey[element]) {
          aKey = '';
          return;
        }

        if (!bKey[element]) {
          bKey = '';
          return;
        }

        aKey = aKey[element];
        bKey = bKey[element];
      });

      if (aKey < bKey) return direction === 'ascending' ? -1 : 1;
      if (aKey > bKey) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const renderSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);

      const sortedRecords = sortArray(originalRecords, sortConfig.key, sortConfig.direction);
      setCurrentRecords(sortedRecords.slice(indexFirst, indexLast));
    }
  };

  const assignStatusClicked = (referral) => {
    const requestInfo = JSON.parse(
      referral.request_information || '{}'
    )
    const staffDetails = requestInfo.staff_details || {}

    setModalTitle(`Update Status for  ${staffDetails.staff_name}`);
    setReferralId(referral.id);
    setSelectedStatus(referral.request_status);
    setShowModal(true);
    setAssignStatus(true);
  };

  const ModalBody = () => {
    if (assignStatus) {
      return <UpdateStatusElement />
    }
  }

  const UpdateStatusElement = () => {
    const updateStatus = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/referrals/update-status/${referralId}`, {
          status_id: selectedStatus,
        });

        createInfo("success", "Status updated successfully");
        getEnquiries();
      } catch (error) {
        console.error(error);
        createInfo("error", error.response?.data?.message || "Failed to update status");
      } finally {
        resetModal();
        setShowLoader(false);
      }
    };

    return (
      <form onSubmit={updateStatus}>
        <div className="mb-3">
          <label htmlFor="statusSelect" className="block font-medium text-gray-600 text-sm">
            Statuses <span className="text-red-500">*</span>
          </label>
          <select
            value={selectedStatus}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md capitalize"
            required
            id="statusSelect"
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option disabled value="">
              Select a status
            </option>
            {orgStatuses?.map((status) => (
              <option key={status.id} value={status.id}>
                {status?.service_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Update Status</button>
          <button type="button" className="btn red mt-4 w-1/2" onClick={resetModal}>
            Cancel
          </button>
        </div>
      </form>
    );
  };

  return (
    <section>
      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}
      <ul className="flex just-between flex-wrap">
        <li className="w-1/4 mb-6 mr-3 cursor-pointer">

        </li>
      </ul>
      <ViewClaimSlideOut claimOpen={claimOpen} setClaimOpen={setClaimOpen} />
      <section className="">
        <div className='relative'>
          <div className="flex items-center w-full justify-between"></div>
          <div className="pb-4 flex items-center justify-between">
            <div className="relative flex items-center space-x-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={handleSearch}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                placeholder="Search Referrals"
              />
              {can('view client status') &&
                <div className="ml-auto relative">
                  <select
                    onChange={handleStatusChange}
                    value={dropdownValue}
                    className="px-4 border border-gray-300 rounded-md mr-2"
                  >
                    <option value="">Filter by Status</option>
                    {orgStatuses.length > 0 && orgStatuses?.map((status, index) => {
                      return <option key={index} value={status.service_name}>
                        {status.service_name}
                      </option>
                    })}
                  </select>
                  {isFilterApplied() && (
                    <button
                      onClick={resetFilters}
                      className="bg-red-500 text-white rounded-lg px-4 py-2 mr-2"
                    >
                      Reset Filters
                    </button>
                  )}
                </div>
              }
            </div>

            <div onClick={createClaimClicked}>
              <FontAwesomeIcon icon={faPlusCircle} className='text-lg text-nav hover:text-nav-dark cursor-pointer' />
            </div>
          </div>

          <div className="overflow-x-scroll">
            <table className="table-main custom-min-width rounded-b-md rounded-tr-lg overflow-x-scroll">
              <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
                <tr className="bg-[#02ADAD] text-xs">
                  <th scope="col" className="px-2 py-4">
                    Reference
                  </th>
                  <th scope="col" className="px-2 py-4">Name</th>
                  <th scope="col" className="px-2 py-4">
                    Staff Member
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Start Date
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Status
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Additional Info
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Created By
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Created On
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.length > 0 ? (
                  currentRecords.map((item, index) => {
                    const requestInfo = JSON.parse(
                      item.request_information || '{}'
                    )
                    const staffDetails = requestInfo.staff_details || {}

                    return (
                      <tr
                        key={index}
                        className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600 text-xs"
                      >
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {requestInfo.claim_reference ?? ''}
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {staffDetails.academy_name || ''}
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {staffDetails.staff_name || ''}
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {staffDetails.start_date
                            ? moment(staffDetails.start_date).format('DD/MM/YYYY')
                            : 'N/A'}
                        </td>
                        <td className="text-xs py-3 w-1/6">
                          <span
                            className={`${statusClass(
                              item.request_status
                            )} rounded-full px-2 py-1 capitalize font-medium`}
                          >
                            {item.request_status}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {item.description && item.description.length > 200
                            ? `${item.description.slice(0, 200)}...`
                            : item.description || ''}
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium capitalize">
                          {item.created_by_name}
                        </td>
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {moment(item.created_at).format('DD/MM/YYYY')}
                        </td>
                        <td>

                          <Menu as="div" className="inline-block text-left overflow-visible">
                            <div>
                              <Menu.Button className="flex items-center rounded-md cursor-pointer ml-2 hover:bg-gray-300 px-2 py-1">
                                <span className="font-semibold text-sm mr-2">
                                  Actions
                                </span>
                                <FontAwesomeIcon icon={faChevronDown} />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <div>
                                <Menu.Items className="absolute z-10 right-6 mt-2  divide-y divide-gray-100 rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="p-2 space-y-2">
                                    <Menu.Item>
                                      <div onClick={() => handleViewRequest(item)} title="View Referral" className="flex justify-start items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                        <FontAwesomeIcon icon={faEye} className='text-blue-800' /> <div className='pl-2'>View</div>
                                      </div>
                                    </Menu.Item>
                                    <Menu.Item>
                                      <div onClick={() => assignStatusClicked(item)} title="Assign User" className="flex justify-start items-center rounded-md px-2 py-1 hover:bg-pink-200 cursor-pointer">
                                        <FontAwesomeIcon icon={faUserPlus} className='text-pink-800' /> <div className='pl-2'>Update Status</div>
                                      </div>
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </div>
                            </Transition>
                          </Menu>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <p className="no-records">No Requests found</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      </section>
      <ViewRequest
        isOpen={modalOpen}
        onClose={closeModal}
        viewingRequest={selectedRequest}
      />
    </section>
  )
}

export default OrgPPQ

