/* eslint-disable */
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import Modal from '../../components/Modal'
import PPQTitle from '../../components/PPQTitle'
import RequestNotes from './Partials/RequestNotes'
import RequestInfo from './Partials/RequestInfo'
import useAuth from '../../hooks/useAuth'
import OHResultForm from '../../components/OHResultForm'
import moment from 'moment'

function PPQReview() {
  const axios = useAxiosPrivate()
  const { id, taskId } = useParams()
  const { setShowLoader, createInfo, can } = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [submissionDate, setSubmissionDate] = useState(null)
  const [staffDetails, setStaffDetails] = useState([])
  const [claimReference, setClaimReference] = useState('')
  const [requestInfo, setRequestInfo] = useState('')

  const [generalNotes, setGeneralNotes] = useState([])
  const [nurseNotes, setNurseNotes] = useState([])
  
  const [modalTitle, setModalTitle] = useState('')
  const [noteBody, setNoteBody] = useState({})
  const [noteType, setNoteType] = useState(false)
  const [orgDefined, setOrgDefined] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  const [schoolInfo, setSchoolInfo] = useState([])
  const [questionInfo, setQuestionInfo] = useState([])
  const [requestData, setRequestData] = useState(null)
  const [isComplete, setCompleted] = useState(null)
  
  useEffect(() => {
    if(taskId) {
      getTaskInfo()
      can('access nurse notes') ? getAllNotes() : getGeneralNotes()
    }
  }, [])

  const getTaskInfo = async () => {
    const { data } = await axios.get(`tasks/get-task-info/${taskId}`);
    const { task_info } = data.result;
    const { request_information } = task_info;
  
    setRequestInfo(request_information);
    setClaimReference(request_information.claim_reference);
    setSubmissionDate(moment(request_information.submission_date).format('DD/MM/YYYY'));
    setOrgDefined(data.result.orgExists);
    setCompleted(data.result.completed === 1);

    const formData = request_information.form_data;
    let schoolInfo = [];
    let staffDetails = [];
    let questionInfo = [];

    let requestDataArray = {
      wbs_case_id: request_information.wbs_case_id,
      claim_reference: request_information.claim_reference
    };
    for (const [key, value] of Object.entries(formData)) {
      if (!value.visible) continue;

      switch (value.group) {
        case 0:
          schoolInfo[value.order] = { title: value.title, value: value.value };
          break;
        case 1:
          staffDetails[value.order] = { title: value.title, value: value.value };
          break;
        case 2:
          questionInfo[value.order] = { title: value.title, value: value.value };
          break;
      }

      switch (value.title) {
        case 'School Contact Name':
          requestDataArray.employer = value.value;
          break;
        case 'School Contact Email':
          requestDataArray.employerEmail = value.value;
          break;
        case 'Forename':
          requestDataArray.employeeName = `${value.value} ${requestDataArray.employeeName || ''}`;
          break;
        case 'Surname':
          requestDataArray.employeeName = `${requestDataArray.employeeName || ''}${value.value}`;
          break;
        case 'Date of Birth':
          requestDataArray.employeeDOB = moment(value.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
          break;
        case 'Role':
          requestDataArray.employeeRole = value.value;
          break;
      }
    }

    setRequestData(requestDataArray)
    setSchoolInfo(schoolInfo.filter(Boolean));
    setStaffDetails(staffDetails.filter(Boolean));
    setQuestionInfo(questionInfo.filter(Boolean));
  };

  const getAllNotes = async () => {
    const response = await axios.get(`tasks/get-all-notes/${id}`)
    let data = response.data.result
    setGeneralNotes(data.generalNotes);
    setNurseNotes(data.nurseNotes);
  }

  const getGeneralNotes = async () => {
    const response = await axios.get(`tasks/get-general-notes/${id}`)
    let data = response.data.result
    setGeneralNotes(data);
  }

  const ModalBody = () => {
    if(modalType == 'Add') {
      return <AddNoteElement />
    } else if(modalType == 'Delete') {
      return <DeleteNoteElement />
    } else if(modalType == 'Complete') {
      return <OHResultForm requestData={requestData} resetModal={resetModal} taskId={taskId} />
    }
  }

  const AddNoteElement = () => {
    const createNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/service-request-notes/create`, {
          service_request_id: id,
          note_type_id: noteType ? 2 : 1,
          request_note: noteBody.body
        });
  
        if (response.data.error === false) {
          createInfo('success', `Note Added`);
          setNoteBody({});

          const newNote = response.data.result[0];
          if (!noteType) {
            setGeneralNotes(prevNotes => [...prevNotes, newNote]);
          } else {
            setNurseNotes(prevNotes => [...prevNotes, newNote]);
          }
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', `Failed to create note`);
      }
      resetModal();
      setShowLoader(false);
    };

    return (
      <form onSubmit={createNote}>
        <label className='text-xs' htmlFor="">Description <span className='text-red-500'>*</span></label>
        <textarea
          value={noteBody.body}
          onChange={(e) => noteBody.body = e.target.value}
          required
          className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md focus:ring-violet-500 focus:border-violet-500"
        ></textarea>
        <div className="flex items-center mt-2">
          <input
            type="checkbox"
            defaultChecked={can('access nurse notes') ? true : false}
            onChange={e => setNoteType(e.target.checked)}
            className="mr-2"
          />
          <label className="text-xs" htmlFor="">Nurse note</label>
        </div>
        <div className="text-xs mt-2 flex justify-end">
          <span className="text-red-500">*</span> denotes required fields.
        </div>
        <div className="flex mt-2 w-full">
          <button type="submit" className="btn mt-4 mr-4 w-1/2">Add Note</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const DeleteNoteElement = () => {
    const destroyNote = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      try {
        const response = await axios.post(`/service-request-notes/delete/${selectedNote?.id}`);
        if (response.status === 200) {
          createInfo('success', 'Note deleted successfully');
          if (selectedNote.note_type_id === 1) {
            setGeneralNotes(generalNotes => generalNotes.filter(n => n.id !== selectedNote.id));
          } else if (selectedNote.note_type_id === 2) { 
            setNurseNotes(nurseNotes => nurseNotes.filter(n => n.id !== selectedNote.id));
          }
        }
      } catch (error) {
        console.error(error.message);
        createInfo('error', 'Failed to delete note');
      }
      setShowLoader(false);
      resetModal();
    };

    return (
      <form onSubmit={destroyNote}>
        <div className="mb-3">
         Are you sure you want to delete <span className='font-medium'>"{selectedNote.request_note}"</span>?
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Delete note</button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const openCompleteReviewModal = () => {
    setShowModal(true)
    setModalType('Complete')
    setModalTitle('Complete Review')
  }

  const openAddNoteModal = () => {
    setShowModal(true)
    setModalType('Add')
    setModalTitle('Add Note')
  }

  const openDeleteNoteModal = (note) => {
    setShowModal(true)
    setSelectedNote(note)
    setModalType('Delete')
    setModalTitle('Delete Note')
  }

  const resetModal = () => {
    setShowModal(false)
    setModalTitle('')
  }

  return (
    <section>
      {showModal && (
        <Modal 
          title={modalTitle} 
          body={<ModalBody />} 
          show={resetModal} 
          width={modalTitle === 'Complete Review' ? '650px' : undefined} 
        />
      )}

      <div className='text-gray-600 max-w-[74rem] 2xl:max-w-none'>
        <div className='w-full'>
          <PPQTitle
            submissionDate={submissionDate}
            claimReference={claimReference}
            orgDefined={orgDefined}
            backButton
          />
        </div>
        <div className='flex w-full space-x-4'>
          <div className="w-2/3">
            <RequestInfo schoolInfo={schoolInfo} staffDetails={staffDetails} questionInfo={questionInfo} />
          </div>
          <div className='w-1/3'>
            <div className="w-full bg-white rounded-xl shadow-md p-4 mb-4">
              <h2 className="font-bold text-xl mb-2">Actions</h2>
              
              { !isComplete ? (
                <>
                  <button
                    className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={() => openAddNoteModal()}
                  >
                    Add Note
                  </button>
                  <button
                    className="bg-nav hover:bg-nav-dark text-white font-bold py-2 px-4 rounded"
                    onClick={() => openCompleteReviewModal()}
                  >
                    Complete Review
                  </button>
                </>
                ) : (
                  <p>The result has been given and can no longer be changed</p>
                )
              }
            </div>
            <RequestNotes generalNotes={generalNotes} nurseNotes={nurseNotes} isNurse={can('access nurse notes')} deleteNote={openDeleteNoteModal} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default PPQReview
