/* eslint-disable */
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import { useState, useEffect } from 'react'
import Pagination from '../../../components/Pagination';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import ViewClaimSlideOut from '../../../components/ViewClaimSlideOut';
import ViewRequest from '../Organisations/ViewRequest';

const Referrals = ({ policy = null, staff = false, staffId = null, claimReference = null }) => {
  const { id } = useParams()
  const axios = useAxiosPrivate()
  const { setShowLoader, createInfo } = useAuth()
  // search
  const [filteredRecords, setFilteredRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentRecords, setCurrentRecords] = useState([])
  const [search, setSearch] = useState('')
  //Modal consts
  const [modalOpen, setModalOpen] = useState(false)

  // No of Records to be displayed on each page
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [originalRecords, setOriginalRecords] = useState([]);
  const [claimOpen, setClaimOpen] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(null)

  const [activeTab, setActiveTab] = useState(policy && policy[0]?.reference || "");
  const [policies, setPolicies] = useState(policy || []);
  const [claimsGenerated, setClaimsGenerated] = useState(false);
  const [count, setCount] = useState(0);

  //
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  useEffect(() => {
    if (claimReference) {
      getReferralByReference(claimReference)
      setModalOpen(true)
    }
  }, [])

  useEffect(() => {
    if(staff) {
      getStaffPolicies()
    }
    if (policies && policies.length > 0) {
      getReferrals()
    }
  }, [id, staffId])

  useEffect(() => {
    if(!claimsGenerated) {
      getReferrals()
    }
  }, [policies])

  useEffect(() => {
    setPolicies(policy || [])
  }, [policy])

  const getStaffPolicies = async () => {
    try {
      setShowLoader(true)
      setOriginalRecords([])

      if(staff && staffId) {
        let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/staff/get-policies?api_token=${process.env.REACT_APP_API_KEY}`;
        const response = await axios.post(url, { staff_id: staffId })
        if (response.data && JSON.stringify(policies) !== JSON.stringify(response.data.data)) {
          setPolicies(response.data.data)
          setActiveTab(response.data.data[0].reference)
        }
      }
    } catch (err) {
      console.error(err)
      createInfo('error', 'Failed to staff policies')
      setShowLoader(false)
    }
  }

  const getReferrals = async () => {
    try {
      if(policies && policies.length > 0) {
        for (const policyItem of policies) {
          let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/organisation/referrals?api_token=${process.env.REACT_APP_API_KEY}`;
          const response = await axios.post(url, { policy: [policyItem], staff_id: staffId })
          if (response.data) {
            setOriginalRecords(prevOriginalRecords => {
              const existingRecordIndex = prevOriginalRecords.findIndex(record => record.reference === policyItem.reference);
              if (existingRecordIndex !== -1) {
                return prevOriginalRecords; // Do nothing if the reference already exists
              }
              return [...prevOriginalRecords, ...response.data.data];
            });

            setClaimsGenerated(true)
            
            if (activeTab === policyItem.reference) {
              setCurrentRecords(response.data.data.find(record => record.reference === activeTab).claims)
            }
          }
        }
      }
      setShowLoader(false)
    } catch (err) {
      console.error(err)
      createInfo('error', 'Failed to get referrals')
      setShowLoader(false)
    }
  }

  const getReferralByReference = async (reference) => {
    try {
      setShowLoader(true)
      let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/organisation/referral-by-reference/${reference}?api_token=${process.env.REACT_APP_API_KEY}`;

      const response = await axios.get(url)
      if (response.data) {
        setSelectedRequest(response.data.data)
        setShowLoader(false)
      }
    } catch (err) {
      setShowLoader(false)
    }
  }

  const getOrgReferral = async () => {
    try {
      setShowLoader(true)
      let url = `${process.env.REACT_APP_KIS_BASE_URL}/api/v1/singl/organisation/referral/${selectedRequest.id}?api_token=${process.env.REACT_APP_API_KEY}`;

      const response = await axios.get(url)
      if (response.data) {
        setSelectedRequest(response.data.data)
      }
    } catch (err) {
    }
    setShowLoader(false)
  }

  const handleViewRequest = (request) => {
    setSelectedRequest(request)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSelectedRequest(null)
  }

  const statusClass = (serviceName) => {
    if (!serviceName) return "bg-gray-200 text-gray-800";
    const lowerName = serviceName.toLowerCase();

    if (lowerName.includes("expired") || lowerName.includes("deleted") || lowerName.includes("incomplete")) {
      return "bg-rose-200 text-rose-800 border-rose-400";
    }

    if (lowerName.includes("no barriers") || lowerName.includes("complete") || lowerName.includes("in house") || lowerName.includes("chargeable") || lowerName.includes("not chargeable")) {
      return "bg-green-200 text-green-800 border-green-400";
    }
    if (lowerName.includes("waiting") || lowerName.includes("follow up") || lowerName.includes("in progress") || lowerName.includes("settle")) {
      return "bg-amber-200 text-amber-800 border-amber-400";
    }

    if (lowerName.includes("acute") || lowerName.includes("gp helpline")) {
      return "bg-blue-200 text-blue-800 border-blue-400";
    }

    if (lowerName.includes("weight management") || lowerName.includes("physiotherapy")) {
      return "bg-teal-200 text-teal-800 border-teal-400";
    }

    if (lowerName.includes("menopause") || lowerName.includes("june")) {
      return "bg-pink-200 text-pink-800 border-pink-400";
    }

    if (lowerName.includes("other") || lowerName.includes("mindful")) {
      return "bg-orange-200 text-orange-800 border-orange-400";
    }

    return "bg-gray-200 text-gray-800";
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase().trim();
    setSearch(searchValue);

    if (searchValue.length > 0) {
      const filtered = currentRecords.filter((record) => {
        const requestInfo = JSON.parse(record.service_request?.request_information || '{}');
        const staffDetails = requestInfo?.staff_details || {};

        return (
          (record.claim_reference && record.claim_reference.toLowerCase().includes(searchValue)) ||
          (record.case_service?.wbs_service?.service_name && record.case_service.wbs_service.service_name.toLowerCase().includes(searchValue)) ||
          (record.created_by_user && record.created_by_user.toLowerCase().includes(searchValue)) ||
          (record.created_at && moment(record.created_at).format('DD/MM/YYYY').includes(searchValue)) ||
          (staffDetails.staff_name && staffDetails.staff_name.toLowerCase().includes(searchValue)) ||
          (staffDetails.academy_name && staffDetails.academy_name.toLowerCase().includes(searchValue))
        );
      });
      setFilteredRecords(filtered);
      setCurrentRecords(filtered.slice(0, recordsPerPage));
      setNumberOfPages(Math.ceil(filtered.length / recordsPerPage));
    } else {
      setFilteredRecords(originalRecords.reduce((acc, curr) => [...acc, ...curr.claims], []));
      setCurrentRecords(originalRecords.reduce((acc, curr) => [...acc, ...curr.claims], []).slice(0, recordsPerPage));
      setNumberOfPages(Math.ceil(originalRecords.reduce((acc, curr) => [...acc, ...curr.claims], []).length / recordsPerPage));
    }
    setCurrentPage(1)
  };

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1;
      const indexFirst = newPage * recordsPerPage - recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const indexFirst = (newPage - 1) * recordsPerPage;
      const indexLast = newPage * recordsPerPage;

      setIndexOfFirstRecord(indexFirst);
      setIndexOfLastRecord(indexLast);
      setCurrentPage(newPage);
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  };

  const handleTabChange = (reference) => {
    setActiveTab(reference)
    const foundRecord = originalRecords.find(record => record.reference === reference)
    if (foundRecord) {
      setCurrentRecords(foundRecord.claims)
    } else {
      const intervalId = setInterval(() => {
        const foundRecord = originalRecords.find(record => record.reference === reference)
        if (foundRecord) {
          setCurrentRecords(foundRecord.claims)
          clearInterval(intervalId)
        }
      }, 5000)
    }
  }

  return (
    <section>
      <ViewClaimSlideOut claimOpen={claimOpen} setClaimOpen={setClaimOpen} />
      <section className="">

        <div className='relative'>
          <div className="flex items-center w-full justify-between"></div>
          <div className="pb-4 flex items-center justify-between">
            <div className="relative flex items-center space-x-2">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={handleSearch}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-violet-500 focus:border-violet-500 block w-80 pl-10 p-2.5  "
                placeholder="Search Referrals"
              />
            </div>
          </div>

          {/* Tabs */}
          <div className="mb-8">
            <div className="flex flex-col md:flex-row flex-wrap list-none px-0">
              {policies && policies.map((item) => (
                <button
                  key={item.reference}
                  className={`tab-btn${activeTab === item.reference ? " active" : ""}`}
                  onClick={() => handleTabChange(item.reference)}
                >
                  {item.reference}
                </button>
              ))}
            </div>
          </div>

          <div className="overflow-x-scroll">
            <table className="table-main custom-min-width rounded-b-md rounded-tr-lg overflow-x-scroll">
              <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
                <tr className="bg-[#02ADAD] text-xs">
                  <th scope="col" className="px-2 py-4">
                    Reference
                  </th>
                  {/* <th scope="col" className="px-2 py-4">School</th> */}
                  <th scope="col" className="px-2 py-4">
                    Staff Member
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Start Date
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Status
                  </th>
                  {/* <th scope="col" className="px-2 py-4">
                      Notes
                    </th> */}
                  <th scope="col" className="px-2 py-4">
                    Created By
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Created On
                  </th>
                  <th scope="col" className="px-2 py-4">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {originalRecords.some((item) => item.reference === activeTab) ? (
                  <>
                    {currentRecords.length > 0 ? (
                      currentRecords.map((item, index) => {
                        const requestInfo = JSON.parse(
                          item.service_request?.request_information || '{}'
                        )
                        const staffDetails = requestInfo?.staff_details || {}

                        return (
                          <tr
                            key={index}
                            className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600 text-xs"
                          >
                            <td className="whitespace-nowrap px-2 py-4 font-medium">
                              {item.claim_reference ?? ''}
                            </td>
                            {/* <td className="whitespace-nowrap px-2 py-4 font-medium">
                                {staffDetails.academy_name || ''}
                              </td> */}
                            <td className="whitespace-nowrap px-2 py-4 font-medium">
                              {staffDetails.staff_name || ''}
                            </td>
                            <td className="whitespace-nowrap px-2 py-4 font-medium">
                              {staffDetails.start_date
                                ? moment(staffDetails.start_date).format('DD/MM/YYYY')
                                : 'N/A'}
                            </td>
                            <td className="text-xs py-3 w-1/6">
                              <span
                                className={`${statusClass(
                                  item.case_service?.wbs_service?.service_name
                                )} rounded-full px-2 py-1 capitalize font-medium`}
                              >
                                {item.case_service?.wbs_service?.service_name}
                              </span>
                            </td>
                            {/* <td className="whitespace-nowrap px-2 py-4 font-medium">
                                {item.contact_description 
                                  ? item.contact_description.length > 30 
                                    ? `${item.contact_description.slice(0, 30)}...` 
                                    : item.contact_description
                                  : ''}
                              </td> */}
                            <td className="whitespace-nowrap px-2 py-4 font-medium capitalize">
                              {item.created_by_user}
                            </td>
                            <td className="whitespace-nowrap px-2 py-4 font-medium">
                              {moment(item.created_at).format('DD/MM/YYYY')}
                            </td>
                            <td>
                              <div onClick={() => handleViewRequest(item)} title="View Referral" className=" text-blue-800 items-center px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer">
                                <div >View</div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={9}>
                          <p className="no-records">No Requests found</p>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      <div className="p-4">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="text-2xl text-violet-500 animate-spin"
                        />
                        <span className="ml-2">Loading...</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      </section>
      <ViewRequest
        isOpen={modalOpen}
        onClose={closeModal}
        viewingRequest={selectedRequest}
        onRequestUpdate={getOrgReferral}
      />
    </section>
  )
}

export default Referrals

